const data = [
  {
    title: "Ylellinen hemmottelu äidille",
    price: "159 EUR",
    long_description: "Tämä täydellinen 90 minuuttinen hoito äitienpäivänlahjaksi sisältää klassisen jalkahoidon ja ylellisen kasvohoidon. Jalkahoito päättyy rentouttavaan jalkahierontaan ja lakkaukseen, jonka jälkeen äiti pääsee nauttimaan ainutlaatuisesta holistisesta ELEMIS-kasvohoidosta. Luonnollisen mutta tehokkaan hoidon tulokset näkee välittömästi hoidon jälkeen. Hoito sisältää pääsyn wellness-kylpylään (norm. 29€). Kokonaisvaltaista hemmottelua parhaimmillaan!",
    description:
      "Ylellinen hemmottelu äidille sisältää jalkahoidon ja kasvohoidon sekä pääsyn wellness-kylpylään.",
    imageUrl:
      "https://lahjakortti.langvik.fi/file/system/webshop/image/product-pictures/ws_694fb9ba25fe0d8dd23c5aceca4d6f0a___langvik6bf739b62f0e58c418b6ae6588936198__1587722007.jpg"
  },
  {
    title: "Långvik -lahjakortti Electric Spice",
    price: "50 EUR",
    long_description: "Lahjakortti toimii maksuvälineenä hotellin, ravintolan, spa-hoitolan ja kylpylän palveluihin. Långvikin Congress Wellness Hotel sijaitsee rauhallisessa merenrantamiljöössä, vain puoli tuntia Helsingin keskustasta. Tyylikkäät hotellihuoneet luovat ihanteelliset puitteet matkallesi. Kansainvälisesti korkeatasoinen kylpylähotelli tarjoaa kulinaarisia hemmotteluhetkiä ja ylellistä hyvinvointia.",
    description:
      "Osta avoin Långvik -lahjakortti valitulle summalle. Hemmotteluloma kutsuu. ",
    imageUrl:
      "https://lahjakortti.langvik.fi/file/system/webshop/image/product-pictures/ws_ac80486fa25c550c572acfb1f76d80e2___langvik356f6a7c84d9813020d36ee5dc3e526a__1576755339.png"
  },
  {
    title: "Ravintolalahjakortti",
    price: "50 EUR",
    long_description: "Lahjakortti toimii maksuvälineenä Långvik Bistron palveluihin. Aamusta iltaan palvelevassa monipuolisessa ravintolassa voit pistäytyä lasilliselle, nauttia hyvästä ruoasta tai istua iltaa hieman pidempään. Nauti keittiömme innovatiivisesta lähestymistavasta skandinaaviseen keittiöön tunnelmallisessa ympäristössä.",
    description:
      "Osta avoin ravintolalahjakortti valitulle summalle. Lahjana paketillinen makuelämyksiä.",
    imageUrl:
      "https://lahjakortti.langvik.fi/file/system/webshop/image/product-pictures/ws_a714ed691434db7f6cb2fb6291d37e46___langvik392bf993830c3b73efc7d4defc9ff50e__1576755340.png"
  },
  {
    title: "Wellness -lahjakortti",
    price: "50 EUR",
    long_description: "Lahjakortti toimii maksuvälineenä Långvikin hemmotteleviin spa-hoitoihin, laadukkaisiin spa-tuotteisiin ja kylpylän sisäänpääsymaksuihin. Långvikin kylpylähotellissa voi rentoutua ja virkistäytyä yksin tai yhdessä ystävien kanssa.",
    description:
      "Osta avoin hoitolahjakortti valitulle summalle. Lahjana hemmottelua keholle ja mielelle. ",
    imageUrl:
      "https://lahjakortti.langvik.fi/file/system/webshop/image/product-pictures/ws_8c1ad96dad0d3005a6edd9b5b9fc8776___langvik2220395abbb6401bde395d4b1ecff796__1576755340.png"
  },
  {
    title: "Maistelumenu kahdelle",
    price: "128 EUR",
    long_description: "Lahjakortti sisältää maistelumenun kahdelle henkilölle Långvik Bistron à la carte -ravintolaan. Maistelumenu tarjoaa läpileikkauksen keittiömme upeasta osaamisesta. Inspiroidumme ympäröivästä luonnosta ja skandinaavisista mauista. Tuotamme puhtaita ja tinkimättömiä makuelämyksiä aidoista ja lähellä tuotetuista raaka-aineista.",
    description:
      "Anna lahjaksi maistelumenu kahdelle Långvik Bistron à la carte -ravintolaan.",
    imageUrl:
      "https://lahjakortti.langvik.fi/file/system/webshop/image/product-pictures/ws_0899c6a830495dc0d6f8743275809c58___langvika6dbb52ac7dca77bfa391ec3802dd467__1576755342.png"
  },
  {
    title: "Menu Kirkkonummi kahdelle",
    price: "96 EUR",
    long_description: "Lahjakortti sisältää kolmen ruokalajin tunnelmallisen illallisen Långvik Bistron à la carte -ravintolassa. Menu Kirkkonummi vaihtuu vuodenaikojen mukaan ja siinä yhdistyvät herkulliset maut sekä tarinat lähiympäristön raaka-aineista. ",
    description:
      "Anna lahjaksi Menu Kirkkonummi kahdelle Långvik Bistron à la carte -ravintolaan.",
    imageUrl:
      "https://lahjakortti.langvik.fi/file/system/webshop/image/product-pictures/ws_c117b1f3e50f87a75ca944ff41a0ded9___langvikad42ff8bcc96ec2c2a8bb6726f0baaa9__1576755340.png"
  },
  {
    title: "Långvikin brunssi kahdelle",
    price: "58 EUR",
    long_description: "Tervetuloa kokemaan Å BRUNCH! Tyyliä, tuoreutta ja kattavaa valikoimaa viikonlopun leppoisassa tunnelmassa. Långvikin brunssiin sisältyy klassisia ja herkullisia makuja sekä pääsy elokuviin. Lahjakortti voimassa 6kk.",
    description:
      "Långvikin brunssi sisältää klassisia ja herkullisia makuja sekä pääsyn leffaan.",
    imageUrl:
      "https://lahjakortti.langvik.fi/file/system/webshop/image/product-pictures/ws_57fd841362d0fc07db76ff731d3e19c0___langvik187172e2f41d73ec0aaca0f2f727c265__1600241790.png"
  },
  {
    title: "Majoituslahjakortti",
    price: "185 EUR",
    long_description: "Lahjakortti sisältää 1 yön majoituksen kahdelle henkilölle skandinaavisen tyylikkäässä standard-huoneessa. Majoitus sisältää pääsyn kylpylään ja kuntosalille sekä herkullisen aamiaisen. Kylpylässä on suomalaisen saunan lisäksi muun muassa infrapuna- ja höyrysauna, kuntouima-allas sekä porealtaat. Ne pitävät huolen siitä, että hyvä olo jatkuu vielä pitkään vierailusi jälkeen.",
    description:
      "Anna lahjaksi miniloma Långvikiin.",
    imageUrl:
      "https://lahjakortti.langvik.fi/file/system/webshop/image/product-pictures/ws_1edfb80aae66d81ccdec7e4d9e9188e5___langvik8f3ff78fac0410248bff1ae2ea661af9__1576755341.png"
  },
  {
    title: "Sviittilahjakortti",
    price: "385 EUR",
    long_description: "Lahjakortti sisältää 1 yön majoituksen kahdelle henkilölle poreammeellisessa sviitissä. Långvik Sviitti tai Sauna Sviitti saatavuuden mukaan. Majoitus sisältää pääsyn kylpylään ja kuntosalille sekä herkullisen aamiaisen. Kylpylässä on suomalaisen saunan lisäksi muun muassa infrapuna- ja höyrysauna, kuntouima-allas sekä porealtaat. Ne pitävät huolen siitä, että hyvä olo jatkuu vielä pitkään vierailusi jälkeen.",
    description:
      "Vietä ihana loma poreammeellisessa sviitissä.",
    imageUrl:
      "https://lahjakortti.langvik.fi/file/system/webshop/image/product-pictures/ws_7ab112fcce6b134420bdd6978b744388___langvik81a3b1537e97e0e352fe758ce31f3039__1576755341.png"
  },
  {
    title: "Ylellinen hemmottelu äidille",
    price: "159 EUR",
    long_description: "Tämä täydellinen 90 minuuttinen hoito äitienpäivänlahjaksi sisältää klassisen jalkahoidon ja ylellisen kasvohoidon. Jalkahoito päättyy rentouttavaan jalkahierontaan ja lakkaukseen, jonka jälkeen äiti pääsee nauttimaan ainutlaatuisesta holistisesta ELEMIS-kasvohoidosta. Luonnollisen mutta tehokkaan hoidon tulokset näkee välittömästi hoidon jälkeen. Hoito sisältää pääsyn wellness-kylpylään (norm. 29€). Kokonaisvaltaista hemmottelua parhaimmillaan!",
    description:
      "Ylellinen hemmottelu äidille sisältää jalkahoidon ja kasvohoidon sekä pääsyn wellness-kylpylään.",
    imageUrl:
      "https://lahjakortti.langvik.fi/file/system/webshop/image/product-pictures/ws_694fb9ba25fe0d8dd23c5aceca4d6f0a___langvik6bf739b62f0e58c418b6ae6588936198__1587722007.jpg"
  },
  {
    title: "Långvik -lahjakortti Electric Spice",
    price: "50 EUR",
    long_description: "Lahjakortti toimii maksuvälineenä hotellin, ravintolan, spa-hoitolan ja kylpylän palveluihin. Långvikin Congress Wellness Hotel sijaitsee rauhallisessa merenrantamiljöössä, vain puoli tuntia Helsingin keskustasta. Tyylikkäät hotellihuoneet luovat ihanteelliset puitteet matkallesi. Kansainvälisesti korkeatasoinen kylpylähotelli tarjoaa kulinaarisia hemmotteluhetkiä ja ylellistä hyvinvointia.",
    description:
      "Osta avoin Långvik -lahjakortti valitulle summalle. Hemmotteluloma kutsuu. ",
    imageUrl:
      "https://lahjakortti.langvik.fi/file/system/webshop/image/product-pictures/ws_ac80486fa25c550c572acfb1f76d80e2___langvik356f6a7c84d9813020d36ee5dc3e526a__1576755339.png"
  },
  {
    title: "Ravintolalahjakortti",
    price: "50 EUR",
    long_description: "Lahjakortti toimii maksuvälineenä Långvik Bistron palveluihin. Aamusta iltaan palvelevassa monipuolisessa ravintolassa voit pistäytyä lasilliselle, nauttia hyvästä ruoasta tai istua iltaa hieman pidempään. Nauti keittiömme innovatiivisesta lähestymistavasta skandinaaviseen keittiöön tunnelmallisessa ympäristössä.",
    description:
      "Osta avoin ravintolalahjakortti valitulle summalle. Lahjana paketillinen makuelämyksiä.",
    imageUrl:
      "https://lahjakortti.langvik.fi/file/system/webshop/image/product-pictures/ws_a714ed691434db7f6cb2fb6291d37e46___langvik392bf993830c3b73efc7d4defc9ff50e__1576755340.png"
  },
  {
    title: "Wellness -lahjakortti",
    price: "50 EUR",
    long_description: "Lahjakortti toimii maksuvälineenä Långvikin hemmotteleviin spa-hoitoihin, laadukkaisiin spa-tuotteisiin ja kylpylän sisäänpääsymaksuihin. Långvikin kylpylähotellissa voi rentoutua ja virkistäytyä yksin tai yhdessä ystävien kanssa.",
    description:
      "Osta avoin hoitolahjakortti valitulle summalle. Lahjana hemmottelua keholle ja mielelle. ",
    imageUrl:
      "https://lahjakortti.langvik.fi/file/system/webshop/image/product-pictures/ws_8c1ad96dad0d3005a6edd9b5b9fc8776___langvik2220395abbb6401bde395d4b1ecff796__1576755340.png"
  },
  {
    title: "Maistelumenu kahdelle",
    price: "128 EUR",
    long_description: "Lahjakortti sisältää maistelumenun kahdelle henkilölle Långvik Bistron à la carte -ravintolaan. Maistelumenu tarjoaa läpileikkauksen keittiömme upeasta osaamisesta. Inspiroidumme ympäröivästä luonnosta ja skandinaavisista mauista. Tuotamme puhtaita ja tinkimättömiä makuelämyksiä aidoista ja lähellä tuotetuista raaka-aineista.",
    description:
      "Anna lahjaksi maistelumenu kahdelle Långvik Bistron à la carte -ravintolaan.",
    imageUrl:
      "https://lahjakortti.langvik.fi/file/system/webshop/image/product-pictures/ws_0899c6a830495dc0d6f8743275809c58___langvika6dbb52ac7dca77bfa391ec3802dd467__1576755342.png"
  },
  {
    title: "Menu Kirkkonummi kahdelle",
    price: "96 EUR",
    long_description: "Lahjakortti sisältää kolmen ruokalajin tunnelmallisen illallisen Långvik Bistron à la carte -ravintolassa. Menu Kirkkonummi vaihtuu vuodenaikojen mukaan ja siinä yhdistyvät herkulliset maut sekä tarinat lähiympäristön raaka-aineista. ",
    description:
      "Anna lahjaksi Menu Kirkkonummi kahdelle Långvik Bistron à la carte -ravintolaan.",
    imageUrl:
      "https://lahjakortti.langvik.fi/file/system/webshop/image/product-pictures/ws_c117b1f3e50f87a75ca944ff41a0ded9___langvikad42ff8bcc96ec2c2a8bb6726f0baaa9__1576755340.png"
  },
  {
    title: "Långvikin brunssi kahdelle",
    price: "58 EUR",
    long_description: "Tervetuloa kokemaan Å BRUNCH! Tyyliä, tuoreutta ja kattavaa valikoimaa viikonlopun leppoisassa tunnelmassa. Långvikin brunssiin sisältyy klassisia ja herkullisia makuja sekä pääsy elokuviin. Lahjakortti voimassa 6kk.",
    description:
      "Långvikin brunssi sisältää klassisia ja herkullisia makuja sekä pääsyn leffaan.",
    imageUrl:
      "https://lahjakortti.langvik.fi/file/system/webshop/image/product-pictures/ws_57fd841362d0fc07db76ff731d3e19c0___langvik187172e2f41d73ec0aaca0f2f727c265__1600241790.png"
  },
  {
    title: "Majoituslahjakortti",
    price: "185 EUR",
    long_description: "Lahjakortti sisältää 1 yön majoituksen kahdelle henkilölle skandinaavisen tyylikkäässä standard-huoneessa. Majoitus sisältää pääsyn kylpylään ja kuntosalille sekä herkullisen aamiaisen. Kylpylässä on suomalaisen saunan lisäksi muun muassa infrapuna- ja höyrysauna, kuntouima-allas sekä porealtaat. Ne pitävät huolen siitä, että hyvä olo jatkuu vielä pitkään vierailusi jälkeen.",
    description:
      "Anna lahjaksi miniloma Långvikiin.",
    imageUrl:
      "https://lahjakortti.langvik.fi/file/system/webshop/image/product-pictures/ws_1edfb80aae66d81ccdec7e4d9e9188e5___langvik8f3ff78fac0410248bff1ae2ea661af9__1576755341.png"
  },
  {
    title: "Sviittilahjakortti",
    price: "385 EUR",
    long_description: "Lahjakortti sisältää 1 yön majoituksen kahdelle henkilölle poreammeellisessa sviitissä. Långvik Sviitti tai Sauna Sviitti saatavuuden mukaan. Majoitus sisältää pääsyn kylpylään ja kuntosalille sekä herkullisen aamiaisen. Kylpylässä on suomalaisen saunan lisäksi muun muassa infrapuna- ja höyrysauna, kuntouima-allas sekä porealtaat. Ne pitävät huolen siitä, että hyvä olo jatkuu vielä pitkään vierailusi jälkeen.",
    description:
      "Vietä ihana loma poreammeellisessa sviitissä.",
    imageUrl:
      "https://lahjakortti.langvik.fi/file/system/webshop/image/product-pictures/ws_7ab112fcce6b134420bdd6978b744388___langvik81a3b1537e97e0e352fe758ce31f3039__1576755341.png"
  },
  {
    title: "Ylellinen hemmottelu äidille",
    price: "159 EUR",
    long_description: "Tämä täydellinen 90 minuuttinen hoito äitienpäivänlahjaksi sisältää klassisen jalkahoidon ja ylellisen kasvohoidon. Jalkahoito päättyy rentouttavaan jalkahierontaan ja lakkaukseen, jonka jälkeen äiti pääsee nauttimaan ainutlaatuisesta holistisesta ELEMIS-kasvohoidosta. Luonnollisen mutta tehokkaan hoidon tulokset näkee välittömästi hoidon jälkeen. Hoito sisältää pääsyn wellness-kylpylään (norm. 29€). Kokonaisvaltaista hemmottelua parhaimmillaan!",
    description:
      "Ylellinen hemmottelu äidille sisältää jalkahoidon ja kasvohoidon sekä pääsyn wellness-kylpylään.",
    imageUrl:
      "https://lahjakortti.langvik.fi/file/system/webshop/image/product-pictures/ws_694fb9ba25fe0d8dd23c5aceca4d6f0a___langvik6bf739b62f0e58c418b6ae6588936198__1587722007.jpg"
  },
  {
    title: "Långvik -lahjakortti Electric Spice",
    price: "50 EUR",
    long_description: "Lahjakortti toimii maksuvälineenä hotellin, ravintolan, spa-hoitolan ja kylpylän palveluihin. Långvikin Congress Wellness Hotel sijaitsee rauhallisessa merenrantamiljöössä, vain puoli tuntia Helsingin keskustasta. Tyylikkäät hotellihuoneet luovat ihanteelliset puitteet matkallesi. Kansainvälisesti korkeatasoinen kylpylähotelli tarjoaa kulinaarisia hemmotteluhetkiä ja ylellistä hyvinvointia.",
    description:
      "Osta avoin Långvik -lahjakortti valitulle summalle. Hemmotteluloma kutsuu. ",
    imageUrl:
      "https://lahjakortti.langvik.fi/file/system/webshop/image/product-pictures/ws_ac80486fa25c550c572acfb1f76d80e2___langvik356f6a7c84d9813020d36ee5dc3e526a__1576755339.png"
  },
  {
    title: "Ravintolalahjakortti",
    price: "50 EUR",
    long_description: "Lahjakortti toimii maksuvälineenä Långvik Bistron palveluihin. Aamusta iltaan palvelevassa monipuolisessa ravintolassa voit pistäytyä lasilliselle, nauttia hyvästä ruoasta tai istua iltaa hieman pidempään. Nauti keittiömme innovatiivisesta lähestymistavasta skandinaaviseen keittiöön tunnelmallisessa ympäristössä.",
    description:
      "Osta avoin ravintolalahjakortti valitulle summalle. Lahjana paketillinen makuelämyksiä.",
    imageUrl:
      "https://lahjakortti.langvik.fi/file/system/webshop/image/product-pictures/ws_a714ed691434db7f6cb2fb6291d37e46___langvik392bf993830c3b73efc7d4defc9ff50e__1576755340.png"
  },
  {
    title: "Wellness -lahjakortti",
    price: "50 EUR",
    long_description: "Lahjakortti toimii maksuvälineenä Långvikin hemmotteleviin spa-hoitoihin, laadukkaisiin spa-tuotteisiin ja kylpylän sisäänpääsymaksuihin. Långvikin kylpylähotellissa voi rentoutua ja virkistäytyä yksin tai yhdessä ystävien kanssa.",
    description:
      "Osta avoin hoitolahjakortti valitulle summalle. Lahjana hemmottelua keholle ja mielelle. ",
    imageUrl:
      "https://lahjakortti.langvik.fi/file/system/webshop/image/product-pictures/ws_8c1ad96dad0d3005a6edd9b5b9fc8776___langvik2220395abbb6401bde395d4b1ecff796__1576755340.png"
  },
  {
    title: "Maistelumenu kahdelle",
    price: "128 EUR",
    long_description: "Lahjakortti sisältää maistelumenun kahdelle henkilölle Långvik Bistron à la carte -ravintolaan. Maistelumenu tarjoaa läpileikkauksen keittiömme upeasta osaamisesta. Inspiroidumme ympäröivästä luonnosta ja skandinaavisista mauista. Tuotamme puhtaita ja tinkimättömiä makuelämyksiä aidoista ja lähellä tuotetuista raaka-aineista.",
    description:
      "Anna lahjaksi maistelumenu kahdelle Långvik Bistron à la carte -ravintolaan.",
    imageUrl:
      "https://lahjakortti.langvik.fi/file/system/webshop/image/product-pictures/ws_0899c6a830495dc0d6f8743275809c58___langvika6dbb52ac7dca77bfa391ec3802dd467__1576755342.png"
  },
  {
    title: "Menu Kirkkonummi kahdelle",
    price: "96 EUR",
    long_description: "Lahjakortti sisältää kolmen ruokalajin tunnelmallisen illallisen Långvik Bistron à la carte -ravintolassa. Menu Kirkkonummi vaihtuu vuodenaikojen mukaan ja siinä yhdistyvät herkulliset maut sekä tarinat lähiympäristön raaka-aineista. ",
    description:
      "Anna lahjaksi Menu Kirkkonummi kahdelle Långvik Bistron à la carte -ravintolaan.",
    imageUrl:
      "https://lahjakortti.langvik.fi/file/system/webshop/image/product-pictures/ws_c117b1f3e50f87a75ca944ff41a0ded9___langvikad42ff8bcc96ec2c2a8bb6726f0baaa9__1576755340.png"
  },
  {
    title: "Långvikin brunssi kahdelle",
    price: "58 EUR",
    long_description: "Tervetuloa kokemaan Å BRUNCH! Tyyliä, tuoreutta ja kattavaa valikoimaa viikonlopun leppoisassa tunnelmassa. Långvikin brunssiin sisältyy klassisia ja herkullisia makuja sekä pääsy elokuviin. Lahjakortti voimassa 6kk.",
    description:
      "Långvikin brunssi sisältää klassisia ja herkullisia makuja sekä pääsyn leffaan.",
    imageUrl:
      "https://lahjakortti.langvik.fi/file/system/webshop/image/product-pictures/ws_57fd841362d0fc07db76ff731d3e19c0___langvik187172e2f41d73ec0aaca0f2f727c265__1600241790.png"
  },
  {
    title: "Majoituslahjakortti",
    price: "185 EUR",
    long_description: "Lahjakortti sisältää 1 yön majoituksen kahdelle henkilölle skandinaavisen tyylikkäässä standard-huoneessa. Majoitus sisältää pääsyn kylpylään ja kuntosalille sekä herkullisen aamiaisen. Kylpylässä on suomalaisen saunan lisäksi muun muassa infrapuna- ja höyrysauna, kuntouima-allas sekä porealtaat. Ne pitävät huolen siitä, että hyvä olo jatkuu vielä pitkään vierailusi jälkeen.",
    description:
      "Anna lahjaksi miniloma Långvikiin.",
    imageUrl:
      "https://lahjakortti.langvik.fi/file/system/webshop/image/product-pictures/ws_1edfb80aae66d81ccdec7e4d9e9188e5___langvik8f3ff78fac0410248bff1ae2ea661af9__1576755341.png"
  },
  {
    title: "Sviittilahjakortti",
    price: "385 EUR",
    long_description: "Lahjakortti sisältää 1 yön majoituksen kahdelle henkilölle poreammeellisessa sviitissä. Långvik Sviitti tai Sauna Sviitti saatavuuden mukaan. Majoitus sisältää pääsyn kylpylään ja kuntosalille sekä herkullisen aamiaisen. Kylpylässä on suomalaisen saunan lisäksi muun muassa infrapuna- ja höyrysauna, kuntouima-allas sekä porealtaat. Ne pitävät huolen siitä, että hyvä olo jatkuu vielä pitkään vierailusi jälkeen.",
    description:
      "Vietä ihana loma poreammeellisessa sviitissä.",
    imageUrl:
      "https://lahjakortti.langvik.fi/file/system/webshop/image/product-pictures/ws_7ab112fcce6b134420bdd6978b744388___langvik81a3b1537e97e0e352fe758ce31f3039__1576755341.png"
  }
];

export default data;

