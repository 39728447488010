import React from "react";
import { Grid, Paper } from "@material-ui/core";
import Image from './assets/langvik_lahjakorttikauppa_bg.jpg';
import { makeStyles } from '@material-ui/core/styles';
import Header from "./Header";
import Footer from "./Footer";
import Content from "./Content";
import Hero from "./Hero";

const useStyles = makeStyles({
  paperContainer: {
    backgroundImage: `url(${Image})`
  },
  root: {
    flexGrow: 1,
    minHeight: 200,
    padding: 30,
  },
});

function App() {
  const classes = useStyles();
  return (
    <Grid container direction="column">

      <Grid item>
        <Header />
      </Grid>
      <Paper className={classes.paperContainer}>
      <Grid item container>
      
        <Grid item xs={false} sm={2} />
          <Grid item xs={12} sm={8} className={classes.root} ><Hero /><Content /></Grid>
        <Grid item xs={false} sm={2} />  
      
      </Grid>
      </Paper>

      <Grid item>
        <Footer />
      </Grid>

    </Grid>
  );
};

export default App;
