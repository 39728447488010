import { Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react'
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    padding: theme.spacing(2),
    position: 'absolute',
    top: theme.spacing(5)
  },
  title: {
    textWeight: 'bold',
    color: 'rgb(238, 68, 140)'
  },
  text: {
    color: 'white'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: 'black',
  },
  closeButton: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
    justifyCcontent: 'center',
    alignItems: 'center',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%'
  }
}));

export default function Popup(props) {
  const classes = useStyles();
  const {title, long_description, imageUrl, price, openPopup, setOpenPopup} = props;
  return (
    <Dialog open={openPopup} maxWidth="md" classes={{paper:classes.dialogWrapper}}>
      <DialogTitle>
        <Grid container justify="flex-end">
          <Button size="medium" color="secondary" onClick={() => setOpenPopup(false)} variant="outlined">X</Button>
        </Grid>
      </DialogTitle>

      <DialogContent dividers>
      <Grid container spacing={2}>

        <Grid className={classes.paper} item xs={12} sm={6} >
          <img src={imageUrl} alt="" className={classes.image} />
        </Grid>

        <Grid className={classes.paper} item xs={12} sm={6}>
          <Typography className={classes.title} variant="h6" component="center">{title}</Typography> 
          <Typography className={classes.price} variant="h6" component="center">{price}</Typography> 
          <Typography><br />{long_description} <br /></Typography>
          <Typography><br />Toimitushinnat</Typography>
          <Typography component="bold">PDF -muotoinen lahjakortti sähköpostiin: 0 EUR <br /></Typography>
          <Typography>Tyylikkäälle paperille tulostettu lahjakortti ja kirjekuori kirjattuna kirjeenä: 12 EUR <br /><br /></Typography>

          <Button className={classes.button} color="secondary" size="medium" variant="contained">LISÄÄ OSTOSKORIIN</Button>
        </Grid>

      </Grid>
      </DialogContent>
    </Dialog>
  )
}
