import React from "react";
import { Grid } from "@material-ui/core";
import SimpleCard from "./Card";
import jsonData from './data.js';

const Content = () => {
  const getCard = dataObj => {
    return (
      <Grid item lg={4} md={6} xs={12}>
        <SimpleCard {...dataObj} />
      </Grid>
    );
  };

  return (
    <Grid container spacing={4}>
      {jsonData.map(dataObj => getCard(dataObj))}
    </Grid>
  )
};

export default Content;