import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import { Icon } from '@material-ui/core';
import HotelIcon from './assets/hotel.svg';
import Restaurant from './assets/restaurant.svg';
import Wellness from './assets/wellness.svg';
import Meeting from './assets/meeting.svg';
import WeddingsEvents from './assets/weddings-events.svg';
import GiftCards from './assets/gift-cards.svg';
import News from './assets/news.svg';
import Info from './assets/info.svg';


const text = {
  fontWeight: 'bold'
};

const StyledMenu = withStyles({
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: 'lightGrey',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function CustomizedMenus() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        
        color="black"
        onClick={handleClick}
      >
        <MenuIcon />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem>
          <ListItemIcon>
            <Icon>
              <img src={HotelIcon} height={25} width={25} alt=''/>
            </Icon>
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: text }} primary="HOTELLI" />
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemIcon>
            <Icon>
              <img src={Restaurant} height={25} width={25} alt=''/>
            </Icon>
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: text }} primary="RAVINTOLA" />
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemIcon>
            <Icon>
              <img src={Wellness} height={25} width={25} alt=''/>
            </Icon>
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: text }} primary="WELLNESS" />
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemIcon>
            <Icon>
              <img src={Meeting} height={25} width={25} alt=''/>
            </Icon>
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: text }} primary="KOKOUS" />
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemIcon>
            <Icon>
              <img src={WeddingsEvents} height={25} width={25} alt=''/>
            </Icon>
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: text }} primary="HÄÄT JA JUHLAT" />
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemIcon>
            <Icon>
              <img src={GiftCards} height={25} width={25} alt=''/>
            </Icon>
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: text }} primary="LAHJAKORTIT" />
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemIcon>
            <Icon>
              <img src={News} height={25} width={25} alt=''/>
            </Icon>
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: text }} primary="NEWS" />
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemIcon>
            <Icon>
              <img src={Info} height={25} width={25} alt=''/>
            </Icon>
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ style: text }} primary="INFO" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}