import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { CardMedia } from '@material-ui/core';
import Popup from './Popup';

const useStyles = makeStyles({
  root: {
    maxWidth: '300px',
    backgroundColor: 'black',
    margin: 'auto',
  },
  titleText: {
    fontSize: 14,
    textAlign: 'center',
    textTransform: 'all-uppercase',
    color: 'white'
  },
  pos: {
    marginBottom: 12,
  },
  button: {
    justifyContent: 'center'
  },
  priceText: {
    fontWeight: 'bold',
    color: 'rgb(238, 68, 140)',
  },
  desc: {
    color: 'white',
    textAlign: "center",
  },

});

export default function SimpleCard(props) {
  const classes = useStyles();
  const { title, long_description, description, imageUrl, price } = props
  const [openPopup, setOpenPopup] = useState(false)

  return (
    <Card className={classes.root}>
      <CardHeader className={classes.titleText}
        title={title}
      />

      <CardMedia style={{ height: "200px"}} image={imageUrl} />

      <CardContent>
        <Typography variant="h5" component="center" className={classes.priceText}>
          {price}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
        </Typography>
        <Typography variant="body2" component="p" className={classes.desc}>
          {description}
          <br />
          
        </Typography>
      </CardContent>
      <CardActions className={classes.button}>
        <Button color='secondary' size="medium" variant="contained" onClick={() => setOpenPopup(true)}>LUE LISÄÄ</Button>
      </CardActions>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} long_description={long_description} title={title} price={price} imageUrl={imageUrl}></Popup>
    </Card>
    

  );
}