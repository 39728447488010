import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Image from './assets/lahjaksi_langvik.png' 
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  image: {
    maxWidth: "100%"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: "auto",
    marginBottom: '30px',
    marginTop: '15px',
  },
  text: {
    color: 'white'
  }
}));

export default function Hero() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid className={classes.paper} item xs={12} md={5}>
         
          <img src={Image} alt="" className={classes.image} />
          
        </Grid>
        <Grid className={classes.paper} item xs={12} md={7}>
          <Typography className={classes.text}>Anna lahjaksi Långvikin verkkokaupasta ostettu lahjakortti ylelliseen hemmotteluun ja herkutteluun. Långvikin kylpylän lahjakorttivaihtoehdot ovat majoitus- ja menulahjakortit sekä avoimet euromääräiset lahjakortit.

<br /><br />Lahjakortin tarkemman kuvauksen sekä voimassaoloajan löydät tuotekortilta. Verkkokaupastamme saat aina vahvistuksen automaattisesti sähköpostiisi ostoksen tehtyäsi. Printtilahjakorti saapuvat Suomessa 7 arkipäivän kuluessa. Huomaathan, ettei Långvik vastaa Postin toiminnasta aiheutuvista viivästyksistä
          </Typography>
        </Grid>

      </Grid>
    </div>
  );
}