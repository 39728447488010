import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Logo from './assets/logo_white.jpg'
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: 200,
    padding: theme.spacing(3),
    backgroundColor: 'black'

  },
  logo: {
    flexGrow: 1,
    maxWidth: 250,
  },
  title: {
    color: 'white'
  },
  text: {
    color: 'white'
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: 80,
    minWidth: 250,
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container alignItems="center" justifyContent="space-evenly" spacing={3}>
        <Grid item xs={12} sm={6} md={3} className={classes.paper}>
          <img src={Logo} alt="logo" className={classes.logo} />
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={classes.paper}>
          <Typography variant="h5" className={classes.title}>
          Långvik Congress <br /> Wellness Hotel
          </Typography>
          <Typography className={classes.text}>Tanskarlantie 9 <br /> 02420 Kirkkonummi </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3} className={classes.paper}>
          <Typography variant="h5" className={classes.title}>Ota yhteyttä</Typography>
          <Typography className={classes.text}>puh. +358 (0)9 2959 9301 <br /> vastaanotto@langvik.fi</Typography>
        </Grid>
      </Grid>
    </div>
  );
}